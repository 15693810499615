import React, { useEffect } from "react";

import Logo from "../../assets/images/Logo/FooterLogo.svg";
import { Col, Row } from "react-bootstrap";
import {
  EmailIcon,
  EmailWhiteIcon,
  FacebookIcon,
  InstaIcon,
  PhoneIcon,
  PhoneWhiteIcon,
  TweeterIcon,
  YoytubeIcon,
} from "../../utils/svgicons";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import GoogleStore from "../../assets/images/Home/TopHeader/google_store.png";
import AppleStore from "../../assets/images/Home/TopHeader/apple_store.png";
import linkedIn from "../../assets/images/Footer/LinkedIn.svg";
import facebook from "../../assets/images/Footer/Facebook.svg";
import tweeter from "../../assets/images/Footer/Tweeter.svg";
import insta from "../../assets/images/Footer/Insta.svg";
import youtube from "../../assets/images/Footer/Youtube.svg";

const Footer = () => {
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    let path = location.pathname.split("");

    if (path?.length > 1) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const handleHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };
  const handleSubscribe = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="page-fotter">
        <div className="content container">
          <Row className="align-items-center logo-sec w-100">
            <Col xs={12} sm={12} md={12} lg={6}>
              <div className="brand-logo">
                <img
                  src={Logo}
                  alt="Logo"
                  className="object-fit-contain image-fluid"
                  loading="lazy"
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <div className="footer-follow-us-links d-flex">
                <div className="follow-us-text">Follow us</div>
                <div className="d-flex social-links">
                  <div className="icon-images cursor">
                    <a
                      href="https://www.linkedin.com/company/see-my-clouds"
                      target="_blank"
                    >
                      <img loading="lazy" src={linkedIn} alt="icon" />
                    </a>
                  </div>
                  <div className="icon-images cursor">
                    <a
                      href="https://www.facebook.com/seemycloud"
                      target="_blank"
                    >
                      <img loading="lazy" src={facebook} alt="icon" />
                    </a>
                  </div>
                  <div className="icon-images cursor">
                    <a
                      href="#"
                      // target="_blank"
                    >
                      <img loading="lazy" src={tweeter} alt="icon" />
                    </a>
                  </div>
                  <div className="icon-images cursor">
                    <a
                      href="https://www.instagram.com/seemy.clouds"
                      target="_blank"
                    >
                      <img loading="lazy" src={insta} alt="icon" />
                    </a>
                  </div>
                  <div className="icon-images cursor">
                    <a
                      href="#"
                      // target="_blank"
                    >
                      <img loading="lazy" src={youtube} alt="icon" />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center page-name-sec w-100 mt-4">
            <Col xs={12} sm={12} md={12} lg={6}>
              <div className="d-flex page-links">
                <div className="page-names">
                  <NavLink to={"/"}>Home</NavLink>
                </div>
                <div className="page-names">
                  <NavLink to="/blog">Blog</NavLink>
                </div>
                <div className="page-names">
                  <NavLink to="/about-us">About</NavLink>
                </div>
                <div className="page-names">
                  <NavLink to={"/contact-us"}>Contact Us</NavLink>
                </div>
                <div className="page-names">
                  <NavLink to={"/terms-and-conditions"}>Privacy Policy</NavLink>
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="subscribe-input-email"
            >
              <div className="subscribe-container">
                <input
                  type="email"
                  placeholder="Enter your email address"
                  className="subscribe-input"
                />
                <button
                  className="subscribe-button"
                  onClick={() => handleSubscribe}
                >
                  Subscribe
                </button>
              </div>
            </Col>
          </Row>
          <Row className="copyright-row align-items-center mt-5 w-100">
            <Col xs={12} sm={12} md={12} lg={6}>
              <div className="copy-right-text">
                ©seemycloude 2024. All rights reserved
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <div className="d-flex justify-content-around w-100 mt-5 store_image">
                <div className="image-store">
                  <a
                    href="https://apps.apple.com/us/app/see-my-clouds/id6578448718"
                    target="_blank"
                  >
                    <img
                      src={AppleStore}
                      alt=""
                      className="object-fit-contain image-fluid"
                    />
                  </a>
                </div>
                <div className="image-store">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.cloudprofsolution.seemyclouds"
                    target="_blank"
                  >
                    <img
                      src={GoogleStore}
                      alt=""
                      className="object-fit-contain image-fluid"
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export { Footer };
